import http from "../utils/http";
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
// let resquest = "/testIp";

// get请求
export function getListAPI(params) {
  return http.get(`/getList.json`, params);
}
// post请求
export function postFormAPI(params) {
  return http.post(`/postForm.json`, params);
}
// put 请求
export function putSomeAPI(params) {
  return http.put(`/putSome.json`, params);
}
// delete 请求
export function deleteListAPI(params) {
  return http.delete(`/deleteList.json`, params);
}

//发送登陆短信
export function sendLoginSms(phoneNum, telAreaCode, params) {
  return http.get(
    `/login/sendLoginSms?phoneNum=${phoneNum}&telAreaCode=${telAreaCode}`,
    params
  );
}

// 手机验证登入
export function sendUpdatePwdSms(phoneNum, sms, params) {
  return http.get(
    `/login/loginEnrollBySms?phoneNum=${phoneNum}&sms=${sms}`,
    params
  );
}
// 查询经销商授权列表
export function queryAtPage(applyUser, params) {
  return http.get(`/faImpower/queryAtPage?applyUser=${applyUser}`, params);
}

// 经销商授权申请
export function applyImpower(params) {
  return http.post(`/faImpower/applyImpower`, params);
}

// 查询经销商授权列表
export function queryDealerAuthById(id) {
  return http.get(`/faDealerAuth/openQuery/${id}`,{});
}
